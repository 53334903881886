import React, { Component } from 'react';
import Slider from "react-slick";
import { navigate } from "@reach/router";
// import Layout from '../layout/layout';
import amplitude from 'amplitude-js';
import appstorebtn from '../assets/images/btn-app-store.png';
import playstorebtn from '../assets/images/btn-play-store-new.png';
import bannerImage from '../assets/images/no-image-big.png';
import noSectionImage from '../assets/images/no-image-thumbnail.png';
import scrollDownArrow from '../assets/images/scroll_down.svg';
import ScrollAnimation from 'react-animate-on-scroll';
import CF from '../components/commonfunctions';
import Modal from 'react-bootstrap/Modal';
import ScaleLoader from "react-spinners/ScaleLoader";
import { Link } from "gatsby"

class Home extends Component {
    state = {
        //** Properties of Home Banner *****//
        settings: {
            dots: true,
            infinite: true,
            autoplay: true,
            speed: 500,
            fade: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplaySpeed: 4500
        },
        //***************************/
        banner: [],
        umxSuiteImg: {},
        sections: [],
        data: {},
        name: "",
        open: false

    }
    //************************* component life cycle *******************//
    componentDidMount() {
      if (typeof window !== "undefined") {
        window.onscroll = () => {
          let currentScrollPos = window.pageYOffset;
          if (currentScrollPos > 25) {
            this.setState({ opacity: "0" })
          } else {
            this.setState({ opacity: "1" })
          }
        }
      }
        // window.scroll(0, 0);
        let { data } = this.props;
        // setTimeout(function () {
        //    let
        // }, 2500);
        let { banner, umxSuiteImg, sections } = this.state;
        if (data) {
            data && data.WordPress && data.WordPress.pageBy && data.WordPress.pageBy.acfDemoFields100 && data.WordPress.pageBy.acfDemoFields100.templateHomeContent && data.WordPress.pageBy.acfDemoFields100.templateHomeContent.filter(each => {
                if (each && each.__typename && each.__typename === "WordPress_Page_Acfdemofields100_TemplateHomeContent_HomePageBanner") {
                    banner = each && each.homePageBanner
                }
                if (each && each.__typename && each.__typename === "WordPress_Page_Acfdemofields100_TemplateHomeContent_UmxSuiteSection") {
                    umxSuiteImg = each;
                }
                if (each && each.__typename && each.__typename === "WordPress_Page_Acfdemofields100_TemplateHomeContent_HomePageLeftRightSection") {
                    sections.push(each);
                }
            })
        }
    this.setState({ data, banner, umxSuiteImg, sections })
    amplitude.getInstance().logEvent('PAGE_VIEW_HOME');
    }

    popUp = (name) => {
        this.setState({ open: true, name })
    }

    closeModal = () => {
        this.setState({ open: false })
    }

    render() {
        let { settings, banner, umxSuiteImg, sections, data, open, name, opacity } = this.state;
        let { umxSuiteSectionDescription, umxSuiteSectionTitle, umxSectionCenterLogo, umxImageForContent, umxSectionCenterLogoText } = umxSuiteImg;
        let token = CF.getItem('token');
        return (
            <React.Fragment>
                {!Object.keys(data).length || (data.WordPress && data.WordPress.pageBy && data.WordPress.pageBy.acfDemoFields100.length === 0) ?
                    <div className="product-loader"><ScaleLoader color={'#141618'} size={150} /></div> :
                    <>
                        {/********************* Home Banner  *************************/}
                        <HomeBanner settings={settings} banner={banner} />
                        <div style={{ opacity: `${opacity}`}} className="scroll-down">
                          <div className="arrow bounce">
                            <img src={scrollDownArrow} />
                          </div>
                        </div>
                        {/********************* UMX  *************************/}
                        {umxSuiteImg ? <section className="umx-suite-sec">
                            <div className="container">
                            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                                    <div className="umx-content">
                                        {umxSuiteSectionTitle && <h3>{umxSuiteSectionTitle}</h3>}
                                        {umxSuiteSectionDescription && <p>{umxSuiteSectionDescription}</p>}
                                    </div>
                                    </ScrollAnimation>
                                <ScrollAnimation animateIn='zoomIn' animateOut="ZoomOut">
                                    {umxImageForContent && umxImageForContent.sourceUrl && <div className="umx-img">
                                        <img src={umxImageForContent.sourceUrl} alt="The UMX Suite" />
                                    </div>}
                                </ScrollAnimation>
                            </div>
                            <div className="x-music-wrap">
                                <div className="x-music-round">
                                    <div className="x-music-text">
                                        {umxSectionCenterLogo && umxSectionCenterLogo.sourceUrl && <span>
                                            <img src={umxSectionCenterLogo.sourceUrl} alt="The UMX Suite" />
                                        </span>}
                                        {umxSectionCenterLogoText && <span>{umxSectionCenterLogoText}</span>}
                                    </div>
                                </div>
                            </div>
                        </section> : ""}
                        {/* ******************** Sections  *************************/}
                        {sections && sections.length && sections.map((each, key) => {
                            let iconColor = each && each.leftRightSectionIcon && each.leftRightSectionIcon.leftRightSectionIconColor1;
                            let iconColor2 = each && each.leftRightSectionIcon && each.leftRightSectionIcon.leftRightSectionIconColor2;
                            let iconText = each && each.leftRightSectionIcon && each.leftRightSectionIcon.leftRightSectionIconName;
                            let sectionImage = each && each.leftRightSectionImage && each.leftRightSectionImage.sourceUrl ? each.leftRightSectionImage.sourceUrl : noSectionImage;
                            let iconImages = each && each.leftRightSectionIcon && each.leftRightSectionIcon.leftRightSectionIconImage && each.leftRightSectionIcon.leftRightSectionIconImage.sourceUrl ? each.leftRightSectionIcon.leftRightSectionIconImage.sourceUrl : noSectionImage;
                            let title = each && each.leftRightSectionTitle ? each.leftRightSectionTitle : "";
                            let description = each && each.leftRightSectionDescription ? each.leftRightSectionDescription : "";
                            let imagePosition = each && each.leftRightSectionImagePosition ? each.leftRightSectionImagePosition : "right";
                            let imageAlignment = each && each.leftRightSectionImageAlignment ? each.leftRightSectionImageAlignment : "center";
                            let path = each && each.leftRightSectionIcon && each.leftRightSectionIcon.leftRightSectionIconName ? (each.leftRightSectionIcon.leftRightSectionIconName).toLowerCase() : '';
                            let googleStore = each && each.leftRightSectionGooglePlayStore ? each.leftRightSectionGooglePlayStore : null;
                            let appleStore = each && each.leftRightSectionAppleStore ? each.leftRightSectionAppleStore : null;

                            if (title !== 'Data Stories' && title !== 'News') {
                              return (
                                <section className={(each && each.leftRightSectionBackground === 'dark') ? "landing-page-sec bg-main" : (each && each.leftRightSectionBackground === 'light') ? "landing-page-sec" : 'landing-page-sec'} key={key}>
                                    <div className="container">
                                        <div className={imagePosition === "right" ? "row flex-md-row-reverse align-items-center" : "row align-items-center"}>
                                            <div className="col-md-6">
                                            <ScrollAnimation animateIn={imagePosition === "right" ? "fadeInLeft" : "fadeInRight"} animateOnce={true}>
                                                        <div className="mobile-small-icon">
                                                            <div className="small-icon" >
                                                                {/* style={{ background: `linear-gradient(180deg, ${iconColor} 0%, ${iconColor2} 100%)` }} */}
                                                                <img src={iconImages} alt={title} />
                                                                {/* <span className={iconText === "UMX" ? "small-icon-text small-icon-text-gradient" : "small-icon-text"}>{iconText}</span> */}
                                                            </div>
                                                        </div>
                                                </ScrollAnimation>
                                                <div className={(imagePosition === "right" && imageAlignment === "right") ? "landing-page-img landing-img-pos-right" : (imagePosition === "right" && ((imageAlignment === "center" || imageAlignment === "right") || imageAlignment === "left")) ? "landing-page-img landing-page-img-right" : (imagePosition === "left" && imageAlignment === "left") ? "landing-page-img landing-img-pos-left" : "landing-page-img"}>
                                                    <ScrollAnimation animateIn={imagePosition === "right" ? "fadeInRight" : "fadeInLeft"} animateOnce={true}>
                                                        <img src={sectionImage} alt={title} />
                                                    </ScrollAnimation>
                                                </div>

                                            </div>
                                            <div className="col-md-6">
                                                <ScrollAnimation animateIn={imagePosition === "right" ? "fadeInLeft" : "fadeInRight"} animateOnce={true}>
                                                    <div className={imagePosition === "right" ? "landing-page-content" : "landing-page-content landing-page-content-right"} >
                                                        <div className="small-icon" >
                                                            {/* style={{ background: `linear-gradient(180deg, ${iconColor} 0%, ${iconColor2} 100%)` }} */}
                                                            <img src={iconImages} alt={title} />
                                                            {/* <span className={iconText === "UMX" ? "small-icon-text small-icon-text-gradient" : "small-icon-text"}>{iconText}</span> */}
                                                        </div>
                                                        <h2 className={(each && each.leftRightSectionBackground === 'dark') ? "" : "color-black"}>{title}</h2>
                                                        <p className={(each && each.leftRightSectionBackground === 'dark') ? "" : "color-black"}>{description}</p>
                                                        <Link to={path}>
                                                            <button className={(each && each.leftRightSectionBackground === 'dark') ? "btn btn-brd-white" : "btn btn-brd"} type="button"><span>Learn more</span></button>
                                                        </Link>
                                                        {(title === "Universal Music Discover" || title === "Universal Music Business Intelligence") && <div style={{ fontSize: '12px', paddingTop: '5px', color: each && each.leftRightSectionBackground === 'dark' ? '#fff' : '#000' }}>*Internal access <strong>only</strong> at this time.</div>}
                                                        <div className="store-btn-group">
                                                            {appleStore && <a href="javascript:;" onClick={() => window.open(appleStore)}><img src={appstorebtn} alt="App Store" /></a>}
                                                            {googleStore && <a href="javascript:;" onClick={() => window.open(googleStore)}><img src={playstorebtn} alt="Play Store" /></a>}
                                                        </div>
                                                    </div>
                                                </ScrollAnimation>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                              );
                            } else {
                              return null;
                            }
                        })}
                    </>}
                <PopUp open={open} name={name} onHide={this.closeModal} />
            </React.Fragment>);
    }
}
export default Home;

//******************** Home Banner ************************//
const HomeBanner = ({ settings, banner }) => {
    return (
        <section className="main-banner">
            <React.Fragment>
                <Slider {...settings} >
                    {banner && banner.length && banner.map((each, key) => {
                        let image = each && each.homePageBannerImage && each.homePageBannerImage.sourceUrl ? each.homePageBannerImage.sourceUrl : bannerImage;
                        return (<div className="banner-section" key={key}>
                            <img src={image} alt="music" />
                        </div>)
                    })}
                </Slider>
            </React.Fragment>
        </section>
    );
}

const PopUp = ({ open, onHide, name }) => {
    return (
        <React.Fragment>
            <Modal show={open} id="enterLoginForm" className="modal-dialog-centered" onHide={onHide}>
                <Modal.Body>
                    <div className="form-box">
                        <h6 className="color-white">{name}</h6>
                        <button className="btn btn-green mt-3" type="button" onClick={() => navigate('/login')}><span>Click here to login</span></button>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}
